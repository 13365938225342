<template>
  <div class="mobile-user-header">
    <div class="header-box">
      <div class="left-side">
        <div class="hamburger-line" @click="toggle">
          <div class="line"></div>
          <div class="line"></div>
          <div class="line"></div>
        </div>
        <div class="title">
          <span>Smart Forex</span>
        </div>
      </div>
      <div class="right-side">
        <div class="notification-icon">
          <img src="./img/bell.svg" alt="notification">
        </div>
        <div class="person-icon">
          <img src="./img/user.svg" alt="person">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
export default {
  computed: {
    ...mapState('App', ['showMobileNavbar'])
  },

  methods: {
    ...mapMutations('App', ['TOGGLE_USER_MOBILE_NAVBAR']),

    toggle() {
      this.TOGGLE_USER_MOBILE_NAVBAR(!this.showMobileNavbar)
    }
  }
}
</script>

<style lang="scss" src="./styles.scss">
</style>