<template>
  <div class="mobile-layout">
    <notifications />

    <div class="header">
      <mobileHeader />
      <div class="navbar" :class="{ active: showMobileNavbar}">
          <mobileNavbar />
      </div>
    </div>
    <div class="body">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import mobileHeader from './mobileHeader.vue'
import mobileNavbar from './mobileNavbar.vue'
import { mapState } from 'vuex'
export default {
  components: {
    mobileHeader,
    mobileNavbar
  },

  computed: {
      ...mapState('App', ['showMobileNavbar'])
  }
}
</script>

<style>
</style>