<template>
  <div class="mobile-navbar menu">
    <div class="navbar-menu">
      <nav class="navbar-item">
        <ul class="ul-menu">
          <li class="item" @click="$router.push({ name: 'home' })">Home</li>
          <li class="item" @click="$router.push({ name: 'about' })">About</li>
          <li class="item" @click="$router.push({ name: 'features' })">Features</li>
          <li class="item" @click="$router.push({ name: 'contact' })">Contact</li>
        </ul>
      </nav>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style>
</style>